export const getTeamsList = (state) => state.teams.TeamsList


export const getUsersMappingList = (state) => state.user.UsersMappingList
export const getUserGroupList = (state) => state.user.UserGroupList
export const getSupervisorList = (state) => state.user.SupervisorList
export const getAuditorList = (state) => state.user.AuditorList
export const getRolesList = (state) => state.user.RolesList


export const getErrorList = (state) => state.error.ErrorList
export const getErrorCategoryList = (state) => state.error.ErrorCategoryList


export const getInventoryList = (state) => state.inventory.InventoryList