import React, { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";

export default function FileRecords({ show, closeFunction, data }) {
    useEffect(() => {
        console.log("FileRecords data:", data);
    }, [data]);

    // Prepare columns for DataTable
    const columns = data && data.length > 0
        ? Object.keys(data[0]).map((header) => ({
              name: header,
              selector: (row) => row[header],
              sortable: true,
          }))
        : [];

    return (
        <Offcanvas show={show} onHide={closeFunction} placement="end" className="w-90">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">File Records</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {data && data.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        highlightOnHover
                    />
                ) : (
                    <p>No records to display</p>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
}
