import React, { useState } from "react";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { Badge, Button, Card, Form } from "react-bootstrap";
import SweetAlertService from "../_common/SweetAlertService";
import ToastifyService from "../_common/ToastifyService";
import PendingResponseData from "../../Json/PenddingResponse/pendding-response.json";
import UpdatePendingResponse from "./update-pending-response";
import { useNavigate } from "react-router-dom";

export default function PendingResponse() {
    const [showUpdatePendingResponse, setShowUpdatePendingResponse] = useState(false);
    const handleUpdatePendingResponse = (state) => {
        setShowUpdatePendingResponse(state);
    };

    const columns = [
        
        {
            name: "Audit ID",
            selector: (row) => (
                <h6 className="fw-bold">{row.audit_id}</h6>
            ),
            sortable: true,
        },
        {
            name: "Case ID",
            selector: (row) => row.lims_case_id,
            sortable: true,
        },
        {
            name: "Audit Score",
            selector: (row) => row.audit_score,
            sortable: true,
        },

        {
            name: "Project Name",
            selector: (row) => row.project_name,
            sortable: true,
        },

        {
            name: "Response From",
            selector: (row) => row.response_from,
            sortable: true,
        },
        {
            name: "Comment",
            selector: () => (
                <h6 className="text-wrap pt-1 pb-1 fs-12">This is a sample message from the user.</h6>
            ),
            sortable: true,
        },
        {
            name: "Audit Date",
            selector: (row) => row.audit_date,
            sortable: true,
        },
        {
            name: "Audit Status",
            selector: (row) => (
                <Badge bg={row.status === "Open" ? "warning" : row.status === "Pending TL Response" ? "warning" :
                    row.status === "TL Rebuttal" ? "danger" : row.status === "Closed" ? "success" : "secondary"
                } pill>{row.status}</Badge>
            )
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="primary" className="fs-12" onClick={goStartAudit}>Respond</Button>
                   
                </div>
            ),
        }
    ];

    const navigate = useNavigate();

    const goStartAudit = () => {
      navigate("/start-audit");
    };

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = PendingResponseData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE DELETE CLIENT
    const handleDelete = (data) => {
        SweetAlertService.showAlert(
            data.client_name,
            "Are you sure you want to delete this Agent Name?",
            "warning"
        ).then((result) => {
            if (result.isConfirmed) {
                ToastifyService.success(`Agent Name Deleted Successfully`);
            } else {
                console.log("Delete operation cancelled!");
            }
        }).catch((error) => {
            console.error("Error:", error);
            ToastifyService.error(`something went wrong`);
        });
    };

    return (
        <React.Fragment>
            <Header />
            <UpdatePendingResponse show={showUpdatePendingResponse} closeFunction={handleUpdatePendingResponse} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Pending Response</h4>
                    </div>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Pending Response</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
};