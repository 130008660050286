import React, { useState } from "react";
import DataTable from "react-data-table-component";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import { Button, Card, Form } from "react-bootstrap";
import auditFormData from "../../../Json/ProjectBuilder/audit-form.json";

export default function AuditFormList() {
    const columns = [
        {
            name: "Audit Form ID",
            selector: (row) => row.audit_form_id,
            sortable: true, // Enable sorting for this column
        },

        {
            name: "Audit Form Name",
            selector: (row) => row.audit_form_name,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Created On",
            selector: () => (
                <div>
                    <h6 className="mb-0 fw-semibold">03 Oct 2024</h6>
                    <span className="fs-12 text-dark">12:10 PM</span>
                </div>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: () => (
                <div className="d-flex justify-content-end">
                    <Button variant="outline-danger" className="btn-icon" onClick={()=> handleDeleteAuditForm(true)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            width: "200px",
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = auditFormData?.filter((item) => 
        Object.values(item).some((value) => 
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

      // HANDLE DELETE AUDIT FORM
      const [data, setData] = useState([]);
      const handleDeleteAuditForm = (index) => {
          SweetAlertService.showAlert(
              "Audit Form",
              "Are you sure you want to delete this User?",
              "warning"
          )
              .then((result) => {
                  if (result.isConfirmed) {
                      ToastifyService.success(`User Deleted Successfully`);
                      const newData = [...data];
                      newData.splice(index, 1);
                      setData(newData);
                  }
              })
              .catch((error) => {
                  console.error("Error:", error);
                  ToastifyService.error(`Something went wrong`);
              });
      };

    return (
        <React.Fragment>
            <div className="mb-3 d-flex justify-content-end">
                <Button type="button" variant="primary" className="d-flex align-items-center gap-1">
                <i class="ri-add-line"></i> <span>Create New Audit Form</span>
                </Button>
            </div>
                <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Audit Form</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control 
                                type="text" 
                                placeholder="Search..." 
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)} 
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="custom-common-table">
                        {filteredEmployees && filteredEmployees !== undefined && (
                            <DataTable
                                columns={columns}
                                data={filteredEmployees}
                                fixedHeader
                                highlightOnHover
                                pagination
                            />
                        )}
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )

}