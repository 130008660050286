export const GET_INVENTORY_LIST = 'GET_INVENTORY_LIST';
export const GET_INVENTORY_LIST_SUCCESS = 'GET_INVENTORY_LIST_SUCCESS';

export const InventoryList = (data) => ({
    type: GET_INVENTORY_LIST,
    payload:data
});
export const InventoryListSuccess = (data) => ({
    type: GET_INVENTORY_LIST_SUCCESS,
    payload:data
});