import React, { useState } from "react";
import Select from "react-select";
import { Button, Offcanvas, Form, Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function CreateProject(props) {
    const associatedDataOption = [
        { value: "1", label: "COMBINED PT PR PAID" },
        { value: "2", label: "COMBINED PT NCS PAID" },
        { value: "3", label: "COMBINED OTHER PAID" },
        { value: "4", label: "BIOMARKER STATE" },
        { value: "5", label: "ACCRUED AMT" },
        { value: "6", label: "ACCRUAL QTR" },
        { value: "7", label: "ACCRUAL ACCRUAL GROUP" },
        { value: "8", label: "ACCRUAL PAYOR GROUP" },
        { value: "9", label: "ACCRUAL PAYORID" }  
    ];


    const [selectedTeam, setSelectedTeam] = useState(null);
    const [addedProjectTeam, setAddedProjectTeam] = useState([]);
    const handleAddProjectTeam = () => {
        if (selectedTeam && !addedProjectTeam.includes(selectedTeam)) {
            setAddedProjectTeam([...addedProjectTeam, selectedTeam]);
            setSelectedTeam(null); // Reset the select field
        }
    };

    // Remove error from the table
    const removeProjectTeam = (error) => {
        setAddedProjectTeam(addedProjectTeam.filter((item) => item.value !== error.value));
    };

    return (
        <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">Create New Project</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Row>
                    <Col md={10}>
                        <div className="mb-4">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Project Name" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Description" />
                        </div>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Project Team</Form.Label>
                            <Form.Select className="wt-400">
                                    <option value={1}>IVT-CGS</option>
                                    <option value={2}>IVT-Natera</option>
                                    <option value={3}>IVT-SGH</option>
                                    <option value={4}>PRIOR AUTH - CANCELLATIONS</option>
                                    <option value={5}>PRIOR AUTH - DECISION - VOICE</option>
                                    <option value={6}>DENIALS-CGS</option>
                                    <option value={7}>PRIOR AUTH - SUBMISSIONS - NATERA</option>
                                    <option value={8}>CDS-CGS</option>
                                    <option value={9}>CDS-NATERA</option>
                                    <option value={10}>PRIOR AUTH - DECISIONS - FAX</option>
                                </Form.Select>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                  
                        <div className="mb-4">
                            <Form.Label>Associated Data Columns</Form.Label>
                            <div className="d-flex gap-2 mb-4">
                                <Select className="wt-400"
                                    options={associatedDataOption}
                                    value={selectedTeam}
                                    onChange={setSelectedTeam}
                                    isSearchable={true}
                                    placeholder="Select Error" />
                                <Button type="button" variant="dark" onClick={handleAddProjectTeam}>Include</Button>
                            </div>

                            <div>
                                <h6 className="fs-14 mb-2">Defaults fields</h6>
                                <div className="d-flex gap-2 align-items-center flex-wrap mb-3">
                                <Badge className="fs-14" bg="secondary" pill><span>LIMS Case ID</span></Badge>
                                <Badge className="fs-14" bg="secondary" pill><span>Agent Name</span></Badge>
                                <Badge className="fs-14" bg="secondary" pill><span>Team Name</span></Badge>
                                <Badge className="fs-14" bg="secondary" pill><span>Action Owner</span></Badge>
                                <Badge className="fs-14" bg="secondary" pill><span>Action Date</span></Badge>
                                <Badge className="fs-14" bg="secondary" pill><span>Action Date</span></Badge>
                            </div>
                            </div>
                            
                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                {addedProjectTeam.length === 0 ? (
                                    <h6 className="fs-14 text-center"></h6>
                                ) : (
                                    addedProjectTeam.map((item, index) => (
                                        <Badge bg="primary" className="fs-14" key={index} pill>
                                            <span>{item.label} </span>
                                            <Link className="text-white align-middle ms-1" onClick={() => removeProjectTeam(item)}><i class="ri-close-circle-line"></i></Link>
                                        </Badge>
                                    ))
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Offcanvas.Body>

            <div className="offcanvas-footer justify-content-start">
                <Button
                    type="submit"
                    variant="primary"
                    className="fs-14 me-2 d-flex align-items-center"
                >
                    <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                    <span className="align-middle">Create Project</span>
                </Button>
            </div>
        </Offcanvas>
    );
}
