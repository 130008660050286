import React, { useState } from "react";
import Select from "react-select";
import { Card, Col, Form, Row, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ProjectList() {
    const projectTeamOption = [
        { value: "1", label: "IVT-CGS" },
        { value: "2", label: "IVT-Natera" },
        { value: "3", label: "IVT-SGH" },
        { value: "4", label: "PRIOR AUTH - CANCELLATIONS" },
        { value: "6", label: "PRIOR AUTH - DECISION - VOICE" },
        { value: "7", label: "DENIALS-CGS" },
        { value: "8", label: "PRIOR AUTH - SUBMISSIONS - NATERA" },
        { value: "9", label: "CDS-CGS" },
        { value: "10", label: "CDS-NATERA" },
        { value: "11", label: "PRIOR AUTH - DECISIONS - FAX" },
        { value: "12", label: "PRIOR AUTH - RCM PAMI" },
        { value: "13", label: "PRIOR AUTH - SUBMISSIONS - INFINX" }
    ];

    const [selectedTeam, setSelectedTeam] = useState(null);
    const [addedProjectTeam, setAddedProjectTeam] = useState([]);
    const handleAddProjectTeam = () => {
        if (selectedTeam && !addedProjectTeam.includes(selectedTeam)) {
            setAddedProjectTeam([...addedProjectTeam, selectedTeam]);
            setSelectedTeam(null); // Reset the select field
        }
    };

    // Remove error from the table
    const removeProjectTeam = (error) => {
        setAddedProjectTeam(addedProjectTeam.filter((item) => item.value !== error.value));
    };

    
    return (
        <React.Fragment>
            <Card className="card-one">
                <Card.Body>
                <Row>
                    <Col md={8}>
                        <div className="mb-4">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Project Name" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>
                        <div className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Description" />
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col md={12}>
                        <div className="mb-4">
                            <Form.Label>Map Project Team</Form.Label>
                            <div className="d-flex gap-2 mb-4">
                                <Select className="wt-400"
                                    options={projectTeamOption}
                                    value={selectedTeam}
                                    onChange={setSelectedTeam}
                                    isSearchable={true}
                                    placeholder="Select Error" />
                                <Button type="button" variant="dark" onClick={handleAddProjectTeam}>ADD</Button>
                            </div>

                            <div className="d-flex gap-2 align-items-center flex-wrap">
                                {addedProjectTeam.length === 0 ? (
                                    <h6 className="fs-14 text-center">No Project Team added</h6>
                                ) : (
                                    addedProjectTeam.map((item, index) => (
                                        <Badge className="fs-14" key={index} pill>
                                            <span>{item.label} </span>
                                            <Link className="text-white align-middle" onClick={() => removeProjectTeam(item)}><i class="ri-close-circle-line"></i></Link>
                                        </Badge>
                                    ))
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}