import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { GET_INVENTORY_LIST, InventoryListSuccess } from "../action/inventoryAction";


const InventoryList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_INVENTORY_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "inventoryList" }));
        const inventoryList = await api.inventoryAPI.InventoryList(action.payload);
        
        dispatch(InventoryListSuccess(inventoryList));
        dispatch(Loading.setLoading({ loading: false, value: "inventoryList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

export default [
    InventoryList
  ]