import { GET_INVENTORY_LIST_SUCCESS } from "../action/inventoryAction";

const initialState = {
    InventoryList: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVENTORY_LIST_SUCCESS:
            return { ...state, InventoryList: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;