const DashboardMenu = [
  {
    "label": "Dashboard",
    "link": "dashboard/manage",
    "icon": "ri-dashboard-line"
  }
];

// const QAAuditMenu = [
//   {
//     "label": "QA Audit",
//     "link": "QAAudit/manage",
//     "icon": "ri-line-chart-line"
//   }
// ];

const PendingResponseMenu = [
  {
    "label": "Pending Response",
    "link": "pendingResponse/manage",
    "icon": "ri-history-line",
    "count": 5
  }
];

const ReportAnayticsMenu = [
  {
    "label": "QA Audit",
    "link": "QaAudit/manage",
    "icon": "ri-award-fill",
    "count": 8
  }
];

const InventoryMenu = [
  {
    "label": "Inventory",
    "link": "/inventory/manage",
    "icon": "ri-book-line"
  }
];

// const RoleAssignmentMenu = [
//   {
//     "label": "Role Assignment",
//     "link": "/admin/role-assignment",
//     "icon": "ri-group-line"
//   }
// ];

// const MyAuditMenu = [
//   {
//     "label": "My Audit",
//     "link": "/myaudit/manage",
//     "icon": "ri-file-text-line"
//   }
// ];

// const Projects1Menu = [
//   {
//     "label": "Project Builder",
//     "link": "/projectBuilder/manage",
//     "icon": "ri-file-text-line"
//   }
// ];

// const PagesMenu = [
//   {
//     "label": "Project",
//     "link": "/project/manage",
//     "icon": "ri-file-text-line"
//   }
// ];

const ProjectsMenu = [
  {
    "label": "Project",
    "link": "/projects/manage",
    "icon": "ri-mail-settings-line"
  }
]

const AuditFormMenu = [
  {
    "label": "Audit Form",
    "link": "/auditform/manage",
    "icon": "ri-survey-line"
  }
]

const SystemSettingMenu = [
  {
    "label": "System Setting",
    "link": "/system-settings",
    "icon": "ri-tools-line"
  }
];



export { DashboardMenu, PendingResponseMenu, ReportAnayticsMenu, InventoryMenu, ProjectsMenu, AuditFormMenu, SystemSettingMenu };