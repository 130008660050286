import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import { Button, Form } from "react-bootstrap";
import SweetAlertService from "../../../_common/SweetAlertService";
import ToastifyService from "../../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { ErrorCategoryList, ErrorList, ErrorUpload } from "../../../../application/action/errorAction";
import useAuth from "../../../../hooks/useAuth";
import { getErrorList } from "../../../../application/selector.js/indexSelector";
import { format } from "date-fns";
import { Link } from "react-router-dom";

export default function ErrorType() {
    const [filesInfo, setFilesInfo] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const validFileExtensions = ['.xls', '.xlsx', '.csv'];
    const fileInputRef = useRef(null);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [editRowData, setEditRowData] = useState({});
    const dispatch = useDispatch();
    const { setAuth, auth } = useAuth();
    const ErrorsList = useSelector(getErrorList)
    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (files.length === 0) return; // No file selected
                console.log('files',files[0]);
    
        const formData = new FormData();
        formData.append("CreatedBy", auth.id);
        formData.append("File", files[0]); // Use the first file
    
        dispatch(ErrorUpload(formData)); // Assuming `dispatch` is correctly set up
    
        setError(null);
        setFilesInfo([]);
    };

    // const handleFileUpload = (event) => {
    //     const files = event.target.files;

    //     if (!files.length) return;

    //     const formData= new FormData
    //     formData.append('createdBy',auth.id )
    //     formData.append("file", files[0]);
    //     
    //     dispatch(ErrorUpload(formData))
    //     setError(null);
    //     setFilesInfo([]);

    //     const filePromises = Array.from(files).map(file => {
    //         return new Promise((resolve, reject) => {
    //             const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

    //             if (!validFileExtensions.includes(fileExtension)) {
    //                 reject(`Invalid file type for file: ${file.name}`);
    //             }

    //             const reader = new FileReader();

    //             reader.onload = (e) => {
    //                 const binaryStr = e.target.result;
    //                 const workbook = XLSX.read(binaryStr, { type: "binary" });
    //                 const sheetName = workbook.SheetNames[0];
    //                 const sheet = workbook.Sheets[sheetName];
    //                 const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    //                 if (jsonData.length) {
    //                     const headers = jsonData[0];
    //                     const rows = jsonData.slice(1);

    //                     const cols = headers.map(header => ({
    //                         name: header,
    //                         selector: (row) => row[headers.indexOf(header)],
    //                         sortable: true,
    //                     }));

    //                     cols.push({
    //                         name: "Actions",
    //                         cell: (row, index) => (
    //                             <div className="d-flex gap-2">
    //                                 <Button variant="primary" className="btn-icon" onClick={() => handleEdit(row, index)}><i className="ri-pencil-line"></i></Button>
    //                                 <Button variant="outline-danger" className="btn-icon" onClick={() => handleDelete(index)}><i className="ri-delete-bin-line"></i></Button>
    //                             </div>
    //                         ),
    //                         ignoreRowClick: true,
    //                         allowOverflow: true,
    //                         button: true,
    //                     });

    //                     resolve({ fileName: file.name, headers: headers, rows: rows, rowCount: rows.length });
    //                     setColumns(cols);
    //                     setData(rows);
    //                 } else {
    //                     resolve({
    //                         fileName: file.name,
    //                         headers: [],
    //                         rows: [],
    //                         rowCount: 0,
    //                     });
    //                 }
    //             };

    //             reader.onerror = (error) => reject(error);

    //             reader.readAsBinaryString(file);
    //         });
    //     });

    //     Promise.all(filePromises)
    //         .then((results) => {
    //             setFilesInfo(results);
    //             if (results.length) {
    //                 const firstFile = results[0];
    //                 setData(firstFile.rows);
    //             }
    //         })
    //         .catch((error) => {
    //             setError(error);
    //         });
    // };

    const handleBulkUpload = () => {
        fileInputRef.current.click();
       
    };

    const handleEdit = (row, index) => {
        setEditRowData({ ...row });
        setShowUpdateUser(true);
    };

    const handleSaveEdit = (updatedData) => {
        const newData = [...data];
        newData[editRowData.index] = updatedData;
        setData(newData);
    };

    const handleDelete = (index) => {
        SweetAlertService.showAlert(
            "User",
            "Are you sure you want to delete this User?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`User Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };
const columns = [
        // {
        //   name: "ID",
        //   selector: (row) => <div>{row.id}</div>,
        //   width: "50px",
        // },
        {
            name: "Error Type",
            selector: (row) => (
                <div>
                    {/* <h6 className="mb-0 fw-semibold"> */}
                    {row.errorName}
                        {/* <Link>{row.errorName}</Link> */}
                    {/* </h6> */}
                    {/* <span className="fs-xs text-secondary">{row.emp_username}</span> */}
                </div>
            ),
        },
        {
          name: "Modified On",
          selector: (row) => format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
        },
        {
          name: "Action",
          selector: (row) => (
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="btn-icon me-2"
                onClick={() => handleEdit(true, row)}
              >
                <i className="ri-pencil-line"></i>
              </Button>
              <Button
                variant="outline-danger"
                className="btn-icon"
                onClick={() => handleDelete(row)}
              >
                <i className="ri-delete-bin-line"></i>
              </Button>
            </div>
          ),
          width: "200px",
        },
        ];

    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Error Type</h4>
                </div>
                <div className="d-flex gap-2">
                    <div>
                        <Form.Control
                            type="file"
                            ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleFileUpload}
                        />
                        <Button variant="primary" onClick={handleBulkUpload}>
                            <i className="ri-file-excel-2-line align-middle"></i> Upload Error Type
                        </Button>
                    </div>
                </div>
            </div>

            <div>
                {error && <p className="text-center" style={{ color: "red" }}>{error}</p>}
                {ErrorsList?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={ErrorsList}
                        pagination
                        highlightOnHover
                    />
                ) : (
                    !error && <p className="text-center">No records to display</p>
                )}
            </div>
        </React.Fragment>
    )
}