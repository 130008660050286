import teamsAPI from "./teamsAPI";
import userAPI from "./userAPI";
import errorAPI from "./errorAPI";
import inventoryAPI from "./inventoryAPI"

export default {
  teamsAPI,
  userAPI,
  errorAPI,
  inventoryAPI
}