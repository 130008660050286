import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import ViewlistModal from "./pages/view-list";
import { useNavigate } from "react-router-dom";

export default function PendingAudit() {
    const [showViewlistModal, setShowViewlistModal] = useState(false);
    const handleViewlistModal = (state)=> {
        setShowViewlistModal(state);
    }

    const navigate = useNavigate();

    const goStartAudit = () => {
      navigate("/start-audit");
    };
    return (
        <React.Fragment>
            <ViewlistModal show={showViewlistModal} closeFunction={handleViewlistModal} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16 mb-1">Pending Audit</h4>
                    <h6 className="fs-14 text-black-50">Project wise pending cases to be audited</h6>
                </div>
            </div>

            <Row className="row-cols-auto g-3 g-xl-4 pt-2">
                {[
                    {
                        "number": "368",
                        "label": "Open for Audit",
                        "name": "IVT-CGS",
                    },
                    {
                        "number": "782",
                        "label": "Open for Audit",
                        "name": "IVT-Natera",
                    },
                    {
                        "number": "768",
                        "label": "Open for Audit",
                        "name": "PRIOR AUTH - CANCELLATIONS",
                    },
                    {
                        "number": "21",
                        "label": "Open for Audit",
                        "name": "CDS-CGS",
                    },
                    {
                        "number": "399",
                        "label": "Open for Audit",
                        "name": "CDS-NATERA",
                    },
                    {
                        "number": "380",
                        "label": "Open for Audit",
                        "name": "PRIOR AUTH - SUBMISSIONS - NATERA",
                    },
                    {
                        "number": "355",
                        "label": "Open for Audit",
                        "name": "PRIOR AUTH - DECISION - VOICE",
                    },
                    {
                        "number": "360",
                        "label": "Open for Audit",
                        "name": "PRIOR AUTH - DECISIONS - INFINX",
                    }
                ].map((item, index) => (
                    <Col lg={3} md={3} sm={6} key={index}>
                        <Card className="card-one">
                            <Card.Body>
                                <h2 className="mb-2 fs-24"><span className="fw-semibold">{item.number}</span> <span className="fs-14">{item.label}</span></h2>
                                <h3 className="fs-16 mb-4">{item.name}</h3>
                                <div className="d-flex gap-2">
                                    <Button variant="primary" className="flex-fill" onClick={goStartAudit}>Start Audit</Button>
                                    <Button variant="secondary" className="flex-fill" onClick={()=> handleViewlistModal(true)}>View List</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}