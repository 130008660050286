import React, { useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import Select from 'react-select';

const MyComponent = () => {
    // Initial table data
    const initialData = [
        { fieldName: "LIMS Case ID", action: "Default" },
        { fieldName: "Agent Name", action: "Default" },
        { fieldName: "Team Name", action: "Default" },
        { fieldName: "Action Owner", action: "Default" },
        { fieldName: "Action Date", action: "Default" },
    ];

    // State for table data and selected option
    const [tableData, setTableData] = useState(initialData);
    const [selectedOption, setSelectedOption] = useState(null);

    // Options for Select component
    const associatedDataOption = [
        { value: 'COMBINED PT PR PAID', label: 'COMBINED PT PR PAID' },
        { value: 'COMBINED PT NCS PAID', label: 'COMBINED PT NCS PAID' },
        { value: 'COMBINED OTHER PAID', label: 'COMBINED OTHER PAID' },
        { value: 'BIOMARKER STATE', label: 'BIOMARKER STATE' },
        { value: 'ACCRUED AMT', label: 'ACCRUED AMT' },
        { value: 'ACCRUAL QTR', label: 'ACCRUAL QTR' },
        { value: 'ACCRUAL ACCRUAL GROUP', label: 'ACCRUAL ACCRUAL GROUP' },
        { value: 'ACCRUAL PAYOR GROUP', label: 'ACCRUAL PAYOR GROUP' },
        { value: 'ACCRUAL PAYORID', label: 'ACCRUAL PAYORID' },
        // Add more options here
    ];

    // Handle Include button click
    const handleInclude = () => {
        if (selectedOption) {
            // Check if the option is already in the tableData
            const isAlreadyAdded = tableData.some(
                (row) => row.fieldName === selectedOption.label
            );

            if (!isAlreadyAdded) {
                // Add new row to the table and remove the default action for the new row
                const newData = [...tableData, { fieldName: selectedOption.label, action: '' }];
                setTableData(newData);
                setSelectedOption(null); // Reset the selected option
            }
        }
    };

    // Handle Remove button click
    const handleRemove = (fieldName) => {
        const updatedData = tableData.filter(row => row.fieldName !== fieldName);
        setTableData(updatedData);
    };

    return (
        <div className="mt-3">
            <Form.Label>Associated Data Columns</Form.Label>
            <div className="d-flex gap-2 mb-4">
                <Select
                    className="wt-400"
                    options={associatedDataOption}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    placeholder="Select Data"
                />
                <Button type="button" variant="dark" onClick={handleInclude}>
                    Include
                </Button>
            </div>

            <Table>
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th className="wt-200">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, index) => (
                        <tr key={index}>
                            <td>{row.fieldName}</td>
                            <td>
                                {row.action || (
                                    <Button variant="outline-danger" className='btn-icon' onClick={() => handleRemove(row.fieldName)}>
                                       <i class="ri-close-line"></i>
                                    </Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default MyComponent;
