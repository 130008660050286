import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Header from "../../layouts/Header";
import { Button, Card, Form } from "react-bootstrap";
import SweetAlertService from "../_common/SweetAlertService";
import ToastifyService from "../_common/ToastifyService";
import CreateProject from "./Create/create-project";
import projectData from "../../Json/ProjectBuilder/projects.json";
import UpdateProject from "./Update/update-project";

export default function Projects() {
    const [showCreateProject, setShowCreateProject] = useState(false);
    const handleCreateProject = (state) => {
        setShowCreateProject(state);
    };

    const columns = [
        {
            name: "Project Name",
            selector: (row) => (
                <div>
                    <h6 className="mb-0 ">{row.project_name}</h6>
                    {/* <span className="fs-12 ">{row.description}</span> */}
                </div>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Team Name",
            selector: (row) => row.team_name,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Fields",
            selector: (row) => (
                <h6 className={` fs-14 ${row.Fields === 0 ? "text-danger" : "text-dark fw-bold"}`} pill>
                    {row.Fields === 0 ? "Not Mapped" : row.Fields + " "+ "mapped"}
                </h6>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Audit Form",
            selector: (row) => (
                <h6 className={`fs-14 ${row.audit_form === 0 ? "text-danger" : "text-dark fw-bold"}`} pill>
                    {row.audit_form === 0 ? "Not Found" : row.audit_form}
                </h6>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Created On",
            selector: () => (
                <div>
                    <h6 className="mb-0 ">03 Oct 2024 12:10 PM</h6>
                    {/* <span className="fs-12 text-dark"></span> */}
                </div>
            ),
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: () => (
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="btn-icon me-2" onClick={()=> handleUpdateProject(true)}>
                        <i className="ri-pencil-line"></i>
                    </Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDeleteProject(true)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            width: "200px",
        },
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = projectData?.filter((item) => 
        Object.values(item).some((value) => 
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE UPDATE PROJECT
    const [showUpdateProject, setShowUpdateProject] = useState(false);
    const handleUpdateProject = (state)=> {
        setShowUpdateProject(state);
    };

    // HANDLE DELETE PROJECT
    const [data, setData] = useState([]);
    const handleDeleteProject = (index) => {
        SweetAlertService.showAlert(
            "Project",
            "Are you sure you want to delete this User?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`User Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    return (
        <React.Fragment>
            <Header />
            <CreateProject show={showCreateProject} closeFunction={handleCreateProject} />
            <UpdateProject show={showUpdateProject} closeFunction={handleUpdateProject} />
            <div className="main main-app p-3 p-lg-4">
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <h4 className="fs-16">Projects</h4>
                <Button
                    variant="primary"
                    className="d-flex align-items-center gap-1"
                    onClick={() => handleCreateProject(true)}
                >
                    <i className="ri-add-line fs-16"></i>
                    <span>Create New Project</span>
                </Button>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Projects</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control 
                                type="text" 
                                placeholder="Search..." 
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)} 
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="custom-common-table">
                        {filteredEmployees && filteredEmployees !== undefined && (
                            <DataTable
                                columns={columns}
                                data={filteredEmployees}
                                fixedHeader
                                highlightOnHover
                                pagination
                                // Enable sorting
                            />
                        )}
                    </div>
                </Card.Body>
            </Card>
            </div>
        </React.Fragment>
    );
}
