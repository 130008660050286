import React, { useState } from "react";
import { Button, Card, Offcanvas, Form, Table } from "react-bootstrap";
import Select from "react-select";

export default function MappedUserModal(props) {
    const usersOption = [
        { value: "1", label: "Sanket Panday", category: "Auditor" },
        { value: "2", label: "Rahul Tripati", category: "Team Lead" },
        { value: "3", label: "Mahesh Yadev", category: "Agent" },
        { value: "4", label: "Junaid Katri", category: "Auditor" },
        { value: "5", label: "Mandar Sawant", category: "Team Lead" },
        { value: "6", label: "Umesh Yadev", category: "Auditor" },
        { value: "7", label: "Faraz Khan", category: "Team Lead" },
        { value: "8", label: "Atik Shaikh", category: "Auditor" }
    ];



    const [selectedUser, setSelectedUser] = useState(null);
    const [addedUser, setAddedUser] = useState([]);

    // Add selected error to the table
    const addUserToTable = () => {
        if (selectedUser && !addedUser.includes(selectedUser)) {
            setAddedUser([...addedUser, selectedUser]);
            setSelectedUser(null); // Reset the select field
        }
    };

    // Remove error from the table
    const removeUser = (user) => {
        setAddedUser(addedUser.filter((item) => item.value !== user.value));
    };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-60">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Map Users</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="card-one h-auto">
                        <Card.Header className="justify-content-between">
                            <h3 className="fs-16">User Map</h3>
                            <div className="d-flex gap-2">
                                <Select className="wt-300"
                                    options={usersOption}
                                    value={selectedUser}
                                    onChange={setSelectedUser}
                                    isSearchable={true}
                                    placeholder="Select Error" />
                                <Button type="button" variant="dark" onClick={addUserToTable}>ADD</Button>
                            </div>
                        </Card.Header>
                        <Form>
                            <Card.Body>
                                <div className="common-table">
                                    <Table className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Role</th>
                                                <th>Category</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {addedUser.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5" className="text-center">No errors added</td>
                                                </tr>
                                            ) : (
                                                addedUser.map((user, index) => (
                                                    <tr key={index}>
                                                        <td>{user.label}</td>
                                                        <td>{user.category}</td>
                                                        <td className="w-0">
                                                            <Button variant="outline-danger" className="btn-icon" onClick={() => removeUser(user)}>
                                                                <i className="ri-close-line"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Form>
                    </Card>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Map User</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
