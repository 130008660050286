import errorMiddleware from './errorMiddleware'
import teamsMiddleware from './teamsMiddleware'
import userMiddleware from './userMiddleware'
import inventoryMiddleware from './inventoryMiddleware'
export default [
    ...teamsMiddleware,
    ...userMiddleware,
    ...errorMiddleware,
    ...inventoryMiddleware
]
