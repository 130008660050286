import React, { useState } from "react";
import Header from "../../layouts/Header";
import "./inventory.scss";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import FileRecords from "./file-records";
import ReactApexChart from "react-apexcharts";

export default function Inventory() {
    const [data, setData] = useState([]);
    const [showFileRecords, setShowFileRecords] = useState(false);

    // CHARTS DATA
    const chart = {
        parentHeightOffset: 0,
        stacked: true,
        sparkline: {
            enabled: true
        }
    };

    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };

    const plotOptions = {
        bar: {
            columnWidth: '60%'
        },
    };

    const stroke = {
        curve: 'straight',
        lineCap: 'square'
    };

    const seriesOne = [{
        type: 'column',
        data: [[0, 0], [1, 0], [2, 5], [3, 10], [4, 6], [5, 10], [6, 15], [7, 18], [8, 7], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5]]
    }, {
        type: 'column',
        data: data
    }];

    const optionOne = {
        chart: chart,
        states: states,
        colors: ['#506fd9', '#e5e9f2'],
        plotOptions: plotOptions,
        stroke: stroke,
        fill: { opacity: 1 },
        tooltip: { enabled: false }
    };

    return (
        <React.Fragment>
            <Header />
            <FileRecords show={showFileRecords} closeFunction={() => setShowFileRecords(false)} data={data} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Inventory Summary</h4>
                    </div>
                </div>

                <Row className="g-3 mb-3">
                    <Col lg={4} md={4} sm={4}>
                        <Card className="card-one">
                            <Card.Body>
                                <Row>
                                    <Col xs={7}>
                                        <h3 className="card-value mb-1">4,608</h3>
                                        <label className="card-title fw-medium text-dark mb-1">Pending Cases</label>
                                        <span className="d-block text-muted fs-11 ff-secondary lh-4">Total cases pending for audit</span>
                                    </Col>
                                    <Col xs={5}>
                                        <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={70} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="card-one inventory-card-custom">
                            <Card.Body className="d-flex justify-content-center align-items-center">
                                <div>
                                    <h3 className="fs-14 mb-2 text-center">Upload Excel File</h3>
                                    <div>
                                        <input type="file" style={{ display: "none" }} accept=".xls,.xlsx,.csv" />
                                        <Button variant="primary" className="d-flex alic-align-items-center gap-1">
                                            <i className="ri-chat-upload-line"></i> Inventory Dumb Upload
                                        </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="card-one inventory-card-custom">
                            <Card.Body className="d-flex justify-content-center align-items-center">
                                <div>
                                    <h3 className="fs-14 mb-2 text-center">Upload Excel File</h3>
                                    <div>
                                        <input type="file" style={{ display: "none" }} accept=".xls,.xlsx,.csv" />
                                        <Button variant="primary" className="d-flex alic-align-items-center gap-1">
                                            <i className="ri-chat-upload-line"></i> Bridge Mapping Data Upload
                                        </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Card className="card-one mb-3">
                    <Card.Header>Project wiae inventory list</Card.Header>
                    <Card.Body>
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Dataset</th>
                                    <th>Total Count</th>
                                    <th>Last Uploaded</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Project Name 1</td>
                                    <td><Badge bg="primary" pill>25</Badge></td>
                                    <td>08 Jan 2024 09:55 AM</td>
                                    <td className="w-0">
                                        <div>
                                            <Button variant="dark" className="btn-icon me-2"><i className="ri-eye-line"></i></Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Project Name 2</td>
                                    <td><Badge bg="primary" pill>42</Badge></td>
                                    <td>10 Feb 2024 11:40 AM</td>
                                    <td className="w-0">
                                        <div>
                                            <Button variant="dark" className="btn-icon me-2"><i className="ri-eye-line"></i></Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Project Name 3</td>
                                    <td><Badge bg="primary" pill>36</Badge></td>
                                    <td>06 Mar 2024 12:10 PM</td>
                                    <td className="w-0">
                                        <div>
                                            <Button variant="dark" className="btn-icon me-2"><i className="ri-eye-line"></i></Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Card className="card-one">
                    <Card.Header>Upload Log</Card.Header>
                    <Card.Body>
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Upload ID</th>
                                    <th>Uploaded by</th>
                                    <th>Total Cases</th>
                                    <th>Added on</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>342</td>
                                    <td >Amir Khan</td>
                                    <td className="fw-bold">452</td>
                                    <td>12 Oct 2024</td>
                                    <td className="fw-bold text-danger"><i class="ri-restart-line align-middle"></i> <span className="align-middle">Uploading</span></td>
                                </tr>
                                <tr>
                                    <td>342</td>
                                    <td >Amir Khan</td>
                                    <td className="fw-bold">452</td>
                                    <td>12 Oct 2024</td>
                                    <td>  <Badge className="fs-12" bg="success" pill >Processed</Badge></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}