import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import UpdateAuditForm from "./update-audit-form";

export default function CreateAuditForm(props) {
    const [showUpdateAuditForm, setShowUpdateAuditForm] = useState(false);
    const handleCreateAudit = (state) => {
        setShowUpdateAuditForm(state);
        props.closeFunction(false);
    };

    return (
        <React.Fragment>
            <UpdateAuditForm show={showUpdateAuditForm} closeFunction={handleCreateAudit} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Create New Audit Form</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row>
                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Select className="wt-400">
                                    <option>Choose Project Name</option>
                                    <option value={1}>IVT-CGS</option>
                                    <option value={2}>IVT-Natera</option>
                                    <option value={3}>IVT-SGH</option>
                                    <option value={4}>PRIOR AUTH - CANCELLATIONS</option>
                                    <option value={5}>PRIOR AUTH - DECISION - VOICE</option>
                                    <option value={6}>DENIALS-CGS</option>
                                    <option value={7}>PRIOR AUTH - SUBMISSIONS - NATERA</option>
                                    <option value={8}>CDS-CGS</option>
                                    <option value={9}>CDS-NATERA</option>
                                    <option value={10}>PRIOR AUTH - DECISIONS - FAX</option>
                                </Form.Select>
                            </div>
                        </Col>

                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Audit Form Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Audit Form Name" />
                            </div>
                        </Col>


                        <Col md={12}>
                            <div className="mb-4">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Description" />
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={() => handleCreateAudit(true)}>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Audit Form</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
