import React, { useState } from "react";
import DataTable from "react-data-table-component";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import mappedUsersData from "../../../Json/ProjectBuilder/mapped-users.json";
import { Card, Button, Form } from "react-bootstrap";
import MappedUserModal from "./create-mapped-user";

export default function MappedUsers() {
    const columns = [
        {
            name: "User Name",
            selector: (row) => row.user_name,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Role",
            selector: (row) => row.role,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Added On",
            selector: (row) => row.added_on,
            sortable: true, // Enable sorting for this column
        },
        {
            name: "Action",
            selector: () => (
                <div className="d-flex justify-content-end">
                <Button variant="outline-danger" className="btn-icon" onClick={()=> handleDelete(true)}>
                    <i className="ri-delete-bin-line"></i>
                </Button>
            </div>
            ),
            sortable: true, // Enable sorting for this column
            width: "100px"
        },
    ];

     // SEARCH FILTER TABLE DATA
     const [searchTerm, setSearchTerm] = useState('');
     const filteredEmployees = mappedUsersData?.filter((item) => 
         Object.values(item).some((value) => 
             value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
         )
     );

      // HANDLE DELETE AUDIT FORM
      const [data, setData] = useState([]);
      const handleDelete = (index) => {
          SweetAlertService.showAlert(
              "Audit Form",
              "Are you sure you want to delete this User?",
              "warning"
          )
              .then((result) => {
                  if (result.isConfirmed) {
                      ToastifyService.success(`User Deleted Successfully`);
                      const newData = [...data];
                      newData.splice(index, 1);
                      setData(newData);
                  }
              })
              .catch((error) => {
                  console.error("Error:", error);
                  ToastifyService.error(`Something went wrong`);
              });
      };

      const [showMappedUser, setShowMappedUser]= useState(false);
      const handleshowMappedUser = (state)=>{
        setShowMappedUser(state);
      };

    return(
        <React.Fragment>
            <MappedUserModal show={showMappedUser} closeFunction={handleshowMappedUser} />
            <div className="mb-3 d-flex justify-content-end">
                <Button type="button" variant="primary" className="d-flex align-items-center gap-1" onClick={()=> handleshowMappedUser(true)}>
                <i class="ri-add-line"></i> <span>Map Users</span>
                </Button>
            </div>
           <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Mapped Users</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control 
                                type="text" 
                                placeholder="Search..." 
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)} 
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="custom-common-table">
                        {filteredEmployees && filteredEmployees !== undefined && (
                            <DataTable
                                columns={columns}
                                data={filteredEmployees}
                                fixedHeader
                                highlightOnHover
                                pagination
                            />
                        )}
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}