import React from "react";
import { Badge, Card, Col, Offcanvas, Row, Tab, Tabs } from "react-bootstrap";
import ProjectList from "./project-list";
import AuditFormList from "./audit-form-list";
import MappedUsers from "./mapped-users";

export default function UpdateProject(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Project</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="card-one h-auto mb-4">
                        <Card.Body>
                        <div className="mb-4">
                        <h2 className="fs-16 text-dark d-flex align-items-center mb-1 fw-semibold">Project Name 1</h2>
                        <h6 className="fs-12">In publishing and graphic design, Lorem ipsum is a</h6>
                    </div>

                    <Row>
                        <Col>
                            <h5 className="fs-12 text-dark">Team Name</h5>
                            <h3 className="fs-16">IVT-CGS</h3>
                        </Col>
                        <Col>
                            <h5 className="fs-12 text-dark">Fields</h5>
                            <h3><Badge pill>12</Badge></h3>
                        </Col>
                        <Col>
                            <h5 className="fs-12 text-dark">Audit Form</h5>
                            <h3><Badge pill>12</Badge></h3>
                        </Col>
                        <Col>
                            <h5 className="fs-12 text-dark">Created On</h5>
                            <h3 className="fs-16">03 Oct 2024</h3>
                            <h6 className="fs-12">12:10 PM</h6>
                        </Col>
                    </Row>
                        </Card.Body>
                    </Card>


                    <div className="common-tabs">
                        <Tabs defaultActiveKey="projects_tab" className="sale-on-point-tabs">
                            <Tab eventKey="projects_tab" title={<><h6 className="fs-14 mb-0">Project</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <ProjectList />
                                </div>
                            </Tab>

                            <Tab eventKey="audit_tabtab" title={<><h6 className="fs-14 mb-0">Audit Form</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <AuditFormList />
                                </div>
                            </Tab>

                            <Tab eventKey="mapped_users_tabtab" title={<><h6 className="fs-14 mb-0">Mapped Users</h6></>} tabClassName="custom-tab-header">
                                <div className="custom-tab-body">
                                    <MappedUsers />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}