import React, { useState } from "react";
import Select from "react-select";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";


export default function AuditErrors() {
    const errorsOption = [
        { value: "unauthorized_access", label: "Unauthorized Access", category: "Data Integrity Errors" },
        { value: "invalid_patient_id", label: "Invalid Patient ID", category: "Data Integrity Errors" },
        { value: "incorrect_group_number", label: "Incorrect Group Number", category: "Data Integrity Errors" },
        { value: "network_type_mismatch", label: "Network Type Mismatch", category: "Data Integrity Errors" },
        { value: "policy_number_missing", label: "Policy Number Missing", category: "Data Integrity Errors" },
        { value: "payor_id_not_found", label: "Payor ID Not Found", category: "Data Integrity Errors" },
        { value: "data_overflow_error", label: "Data Overflow Error", category: "Authorization & Security Errors" },
        { value: "incorrect_contract_payor_name", label: "Incorrect Contract Payor Name", category: "Authorization & Security Errors" },
        { value: "data_type_mismatch", label: "Data Type Mismatch", category: "Authorization & Security Errors" },
        { value: "inconsistent_record", label: "Inconsistent Record", category: "Identification & Matching Errors" },
        { value: "invalid_email_format", label: "Invalid Email Format", category: "Identification & Matching Errors" },
        { value: "unauthorized_modification", label: "Unauthorized Modification", category: "Identification & Matching Errors" },
        { value: "incorrect_phone_number", label: "Incorrect Phone Number", category: "Operational & Processing Errors" },
        { value: "address_format_error", label: "Address Format Error", category: "Operational & Processing Errors" },
    ];

    const [selectedError, setSelectedError] = useState(null);
    const [addedErrors, setAddedErrors] = useState([]);

    // Add selected error to the table
    const addErrorToTable = () => {
        if (selectedError && !addedErrors.includes(selectedError)) {
            setAddedErrors([...addedErrors, selectedError]);
            setSelectedError(null); // Reset the select field
        }
    };

    // Remove error from the table
    const removeError = (error) => {
        setAddedErrors(addedErrors.filter((item) => item.value !== error.value));
    };

    return (
        <React.Fragment>
            <Card className="card-one mb-4">
                <Card.Header className="justify-content-between">
                    <h3 className="fs-16">Errors</h3>
                    <div className="d-flex gap-2">
                        <Select className="wt-300"
                            options={errorsOption}
                            value={selectedError}
                            onChange={setSelectedError}
                            isSearchable={true}
                            placeholder="Select Error" />
                        <Button type="button" variant="dark" onClick={addErrorToTable}>ADD</Button>
                    </div>
                </Card.Header>
                <Form>
                    <Card.Body>
                        <h3 className="fs-14 mb-3">Add errror opportunities</h3>
                        <div className="common-table mb-4">
                            <Table className="mb-0" responsive>
                                <thead>
                                    <tr>
                                        <th>Erros</th>
                                        <th>Map to Score</th>
                                        <th>Is Fatal</th>
                                        {/* <th>Category</th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {addedErrors.length === 0 ? (
                                        <tr>
                                            <td colSpan="5" className="text-center">No errors added</td>
                                        </tr>
                                    ) : (
                                        addedErrors.map((error, index) => (
                                            <tr key={index}>
                                                <td>{error.label}</td>
                                                <td>
                                                    <Form.Select className="wt-200">
                                                        <option>Choose Map to Score</option>
                                                        <option value={1}>Yes</option>
                                                        <option value={0}>NO</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Select className="wt-200">
                                                        <option>Is Fatal</option>
                                                        <option value={1}>Non Fatal</option>
                                                        <option value={0}>Fatal</option>
                                                    </Form.Select>
                                                </td>
                                                {/* <td>{error.category}</td> */}
                                                <td>
                                                    <Button variant="outline-danger" className="btn-icon" onClick={() => removeError(error)}>
                                                        <i className="ri-close-line"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </div>

                     
                    </Card.Body>
                </Form>
            </Card>

            <Row>
                <Col md={6}>
                <h3 className="fs-14 mb-3">Wehitage Calculation</h3>
                        <div className="common-table">
                            <Table className="mb-0 border-0" responsive>
                                <thead>
                                    <tr>
                                        <th className="border-0">Error Category</th>
                                        <th className="border-0">Weightage</th>
                                    </tr>
                                </thead>

                                <tbody className="border-0">
                                    <tr>
                                        <td className="border-0">Data Integrity Errors</td>
                                        <td className="border-0">0%</td>
                                    </tr>
                                    <tr>
                                        <td className="border-0">Authorization & Security Errors</td>
                                        <td className="border-0">0%</td>
                                    </tr>
                                    <tr>
                                        <td className="border-0">Identification & Matching Errors</td>
                                        <td className="border-0">0%</td>
                                    </tr>
                                    <tr>
                                        <td className="border-0">Operational & Processing Errors</td>
                                        <td className="border-0">0%</td>
                                    </tr>
                                </tbody>
                                <tfoot className="border-0">
                                    <tr>
                                        <th className="border-0">Total</th>
                                        <th className="text-danger border-0">0%</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                </Col>
            </Row>
        </React.Fragment>
    )
};