import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getRolesList,
  getSupervisorList,
  getTeamsList,
} from "../../../../application/selector.js/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";
import { AddUser } from "../../../../application/action/userAction";
import useAuth from "../../../../hooks/useAuth";

export default function CreateNewUser(props) {
  const RolesList = useSelector(getRolesList);
  const TeamsList = useSelector(getTeamsList);
  const SupervisorList = useSelector(getSupervisorList);
  const dispatch = useDispatch()
  const { setAuth, auth } = useAuth();

  const initialState = {
    fullName: "",
    username: "",
    roleId: 0,
    email: "",
    password: "",
    empCode: "",
    supervisorId: Environment.defaultValue,
    teamId: Environment.defaultValue,
    createdBy: auth.id,
    modifiedBy: auth.id,
  };
  const [formData, setFormData] = useState(initialState);
  console.log(formData);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(formData);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form data:", formData);
    dispatch(AddUser(formData))
    setFormData(initialState)
    props.closeFunction()
  };

  return (
    <Offcanvas
      show={props.show}
      onHide={props.closeFunction}
      placement="end"
      className="w-40"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-16 text-dark">
          Create New User
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>AMD Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter AMD Username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>EMP Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter emp Code"
                name="empCode"
                value={formData.empCode}
                onChange={handleInputChange}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>Role</Form.Label>
              <Form.Select
                name="roleId"
                value={formData.roleId}
                onChange={handleInputChange}
              >
                <option>Choose Role</option>
                {RolesList?.map((role) => (
                  <option key={role.id} value={Number(role.id)}>
                    {role.role}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <Form.Label>Team Name</Form.Label>
              <Form.Select
                name="teamId"
                value={formData.teamId}
                onChange={handleInputChange}
              >
                <option>Choose Team Name</option>
                {TeamsList?.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
        </Row>

        <Row>
          {formData.roleId === 6 && (
            <Col>
              <div className="mb-4">
                <Form.Label>Supervisor</Form.Label>
                <Form.Select
                  name="supervisorId"
                  value={formData.supervisorId}
                  onChange={handleInputChange}
                >
                  <option>Choose Supervisor</option>
                  {SupervisorList?.map((sup) => (
                    <option key={sup.id} value={sup.id}>
                      {sup.fullName}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          )}
          {/* {formData.roleId == 3 && (
            <Col>
              <div className="mb-4">
                <Form.Label>User Group</Form.Label>
                <Form.Select
                  name="supervisor"
                  value={formData.supervisor}
                  onChange={handleInputChange}
                >
                  <option>Choose User Group</option>
                  {SupervisorList?.map((sup) => (
                    <option key={sup.id} value={sup.fullName}>
                      {sup.fullName}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          )} */}
        </Row>
        {/* <Row>
          <Col md={6}>
            <div className="mb-4">
              <Form.Label>Supervisor</Form.Label>
              <Select options={selectSupervisorOptions} isSearchable={true} />
            </div>
          </Col>
        </Row> */}
        {/* <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>Salesforce Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Salesforce Username"
              />
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>Glidiant Username</Form.Label>
              <Form.Control type="text" placeholder="Enter Glidiant Username" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>Care Username</Form.Label>
              <Form.Control type="text" placeholder="Enter Care Username" />
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>Onshore CGS</Form.Label>
              <Form.Control type="text" placeholder="Enter Onshore CGS" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-4">
              <Form.Label>Work Status</Form.Label>
              <Form.Select>
                <option>Choose Status</option>
                <option>Active</option>
                <option>Inactive</option>
              </Form.Select>
            </div>
          </Col>
          <Col>
            <div className="mb-4">
              <Form.Label>Onshore CGS</Form.Label>
              <Form.Control type="text" placeholder="Enter Onshore CGS" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-4">
              <Form.Label>Consolidated Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Consolidated Name" />
            </div>
          </Col>
        </Row> */}
      </Offcanvas.Body>

      <div className="offcanvas-footer justify-content-start">
        <Button
          type="submit"
          variant="primary"
          className="fs-14 me-2 d-flex align-items-center"
          onClick={handleSubmit}
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>
          <span className="align-middle">Create User</span>
        </Button>
      </div>
    </Offcanvas>
  );
}
