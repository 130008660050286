import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import UserGroup from "./UserGroup/user-group";
//import AccessControl from "./AccessControl/access-control";
import ErrorMaster from "./ErrorMaster/error-master";
import UsersManagement from "./userManagement/manage";
import { useDispatch } from "react-redux";
// import { GetAllAuditors, GetAllSupervisor } from "../../application/action/userAction";

export default function SystemSettings() {
    const dispatch = useDispatch()
    const [isSidebarShow, setSidebarShow] = useState(false);
    // // USEEFFECT
    // useEffect(()=>{
    //     dispatch(GetAllSupervisor(2))
    //     dispatch(GetAllAuditors(3))
    // },[])
    return (
        <React.Fragment>
            <Header />

            <Tab.Container id="left-tabs-example" defaultActiveKey="user_group_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="user_group_tab"><i className="ri-group-line"></i> User Group</Nav.Link></Nav.Item>
                            {/* <Nav.Item><Nav.Link eventKey="access_control_tab"><i className="ri-contacts-fill"></i> Access Control</Nav.Link></Nav.Item> */}
                            <Nav.Item><Nav.Link eventKey="error_master_tab"><i className="ri-error-warning-line"></i> Error Master</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="user_management_tab"><i className="ri-user-2-line"></i> User Management</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <Tab.Content>
                            {/* USER GROUP TAB */}
                            <Tab.Pane eventKey="user_group_tab"><UserGroup /></Tab.Pane>

                            {/* ACCESS CONTROL TAB */}
                            {/* <Tab.Pane eventKey="access_control_tab"><AccessControl /></Tab.Pane> */}

                            {/* ERROR MASTER TAB */}
                            <Tab.Pane eventKey="error_master_tab"><ErrorMaster /></Tab.Pane>

                            {/* USER MANAGEMENT TAB */}
                            <Tab.Pane eventKey="user_management_tab"><UsersManagement /></Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
};