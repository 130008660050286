import React, { useState } from "react";
import Header from "../../layouts/Header";
import auditFormData from "../../Json/AuditForm/audit-form.json";
import { Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ToastifyService from "../_common/ToastifyService";
import SweetAlertService from "../_common/SweetAlertService";
import CreateAuditForm from "./create-audit-form";
import UpdateAuditForm from "./update-audit-form";

export default function AuditForm() {
    const columns = [
        {
            name: "Project Name",
            selector: (row) => row.project_name,
            sortable: true,
        },

        {
            name: "Audit Form Name",
            selector: (row) => row.audit_name,
            sortable: true,
        },

        {
            name: "Description",
            selector: (row) => row.des,
            sortable: true,
        },

        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="primary" className="btn-icon me-2" onClick={()=> hanldeUpdateAuditForm(true)}><i className="ri-pencil-line"></i></Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDelete(true)}><i className="ri-delete-bin-line"></i></Button>
                </div>
            ),
            width: "200px"
        }

    ];

      // SEARCH FILTER TABLE DATA
      const [searchTerm, setSearchTerm] = useState('');
      const filteredEmployees = auditFormData?.filter((item) =>
          Object.values(item).some((value) =>
              value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
  
    // HANDLE DELETE FUNCTION
    const [data, setData] = useState([]);
    const handleDelete = (index) => {
        SweetAlertService.showAlert(
            "Audit Form Name",
            "Are you sure you want to delete this Audit Form Name?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`Audit Form Name Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

    const [showCreateAuditForm, setShowCreateAuditForm] = useState(false);
    const handleCreateAuditForm =(state)=> {
        setShowCreateAuditForm(state);
    };

    const [showUpdateAuditForm, setShowUpdateAuditForm] = useState(false);
    const hanldeUpdateAuditForm = (state)=> {
        setShowUpdateAuditForm(state);
    };

    return (
        <React.Fragment>
            <Header />
            <CreateAuditForm show={showCreateAuditForm} closeFunction={handleCreateAuditForm} />
            <UpdateAuditForm show={showUpdateAuditForm} closeFunction={hanldeUpdateAuditForm} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Audit Form</h4>
                    </div>
                    <div>
                        <Button variant="dark" onClick={()=> handleCreateAuditForm(true)}><i className="ri-add-line align-middle"></i> Create New Audit Form</Button>
                    </div>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Audit Form</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}