import React, { useState } from "react";
import OngoingAuditsData from "../../../Json/QAAudit/ongoing-audit.json";
import { Badge, Button, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import UpdateUser from "./edit-closed-edit";
import moment from "moment";

export default function OngoingAudits() {

    const columns = [
        {
            name: "Audit ID",
            selector: (row) => row.audit_id,
        },

        {
            name: "Audit Name",
            selector: (row) => row.agent_name,
        },
        {
            name: "LMIS Case ID",
            selector: (row) => row.lmis_case_id,
        },
        {
            name: "Project Name",
            selector: (row) => row.project_name,
        },

        {
            name: "Audit Date",
            selector: (row) => row.audit_date,
        },

        {
            name: "Audit Status",
            selector: (row) => (
                <Badge bg={row.audit_status === "Open" ? "warning" : row.audit_status === "Pending TL Response" ? "warning" :
                    row.audit_status === "TL Rebuttal" ? "danger" : row.audit_status === "Closed" ? "success" : "secondary"
                } pill>{row.audit_status}</Badge>
            )
        },

        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="primary" className="btn-icon" onClick={() => handleUpdateUser(true)}><i className="ri-pencil-line"></i></Button>
                </div>
            ),
        }
    ];

        // SEARCH FILTER TABLE DATA
        const [searchTerm, setSearchTerm] = useState('');
        const filteredEmployees = OngoingAuditsData?.filter((item) =>
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );


       // HANDLE DELETE
       const [showUpdateUser, setShowUpdateUser] = useState(false);
       const handleUpdateUser = (state) => {
           setShowUpdateUser(state);
       };

    return (
        <React.Fragment>
            <UpdateUser
            show={showUpdateUser}
            closeFunction={handleUpdateUser} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Ongoing Audits</h4>
                </div>
            </div>


            <Card className="card-one">
            <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Ongoing Audits</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={columns}
                        data={filteredEmployees}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                    ></DataTable>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

const isDateInRange = (date, range) => {
    if (range === "all") {
        return true;
    }

    const dateToCheck = moment(date);
    const now = moment();
    const daysAgo = now.subtract(range, "days");

    return dateToCheck.isAfter(daysAgo);
};