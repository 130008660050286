import React, { useState, useEffect } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";

export default function UpdateUser({ show, closeFunction, rowData, columns, onSave }) {
    const [editData, setEditData] = useState({});

    useEffect(() => {
        console.log("Onload Data", rowData);
        if (rowData) {
            setEditData(rowData);
        }
    }, [rowData]);

    const handleEditChange = (e, index) => {
        const newEditData = { ...editData };
        newEditData[index] = e.target.value;
        setEditData(newEditData);
    };

    const handleSaveEdit = () => {
        if (onSave) {
            onSave(editData);
        }
        closeFunction();
    };

    return (
        <React.Fragment>
            <Offcanvas show={show} onHide={closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update User</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row>
                        {columns && columns.map((col, index) =>
                            col.name !== "Actions" ? (
                                <Col md={6} key={index}>
                                    <Form.Group className="mb-4">
                                        <Form.Label className="text-break">{col.name}</Form.Label>
                                        <Form.Control type="text" value={editData[index] || ""} onChange={(e) => handleEditChange(e, index)} />
                                    </Form.Group>
                                </Col>
                            ) : null
                        )}
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={handleSaveEdit}>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Update User</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
