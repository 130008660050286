
import Environment from '../../core/Environment';
import jwtInterceptor from '../../core/helpers/jwtInterceptor';

const InventoryList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  
  const response = await jwtInterceptor.get(`${Environment.USER_URL}GetInventoryList`,{
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response;
};
export default {
    InventoryList,
  }