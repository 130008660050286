import React, { useState } from "react";
import closeAuditData from "../../../Json/QAAudit/close-audit.json";
import { Badge, Button, Card, Form, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";

export default function RecentlyWorked() {
    const columns = [

        {
            name: "Audit ID",
            selector: (row) => (
                <h6 className="fw-bold">{row.audit_id}</h6>
            ),
            sortable: true,
        },
        {
            name: "Case ID",
            selector: (row) => row.lims_case_id,
            sortable: true,
        },
        {
            name: "Audit Score",
            selector: (row) => row.audit_score,
            sortable: true,
        },

        {
            name: "Project Name",
            selector: (row) => row.project_name,
            sortable: true,
        },

        {
            name: "Comment",
            selector: () => (
                <h6 className="text-wrap pt-1 pb-1 fs-12">This is a sample message from the user.</h6>
            ),
            sortable: true,
        },
        {
            name: "Audit Date",
            selector: (row) => row.audit_date,
            sortable: true,
        },
        {
            name: "Audit Status",
            selector: (row) => (
                <Badge bg="success" pill>{row.status}</Badge>
            )
        }
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = closeAuditData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Close Audit</h4>
                </div>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of Close Audit</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div>
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
