import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupervisorList } from "../../../../application/selector.js/indexSelector";
import ToastifyService from "../../../_common/ToastifyService";
import SweetAlertService from "../../../_common/SweetAlertService";
import { Button, Card } from "react-bootstrap";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { GetAllSupervisor } from "../../../../application/action/userAction";

export default function Supervisor() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const SupervisorList = useSelector(getSupervisorList);

  useEffect(() => {
    dispatch(GetAllSupervisor(3));
  }, []);

  // HANDLE DELETE FUNCTION
  const [data, setData] = useState([]);
  const handleDelete = (index) => {
    SweetAlertService.showAlert(
      "Supervisor",
      "Are you sure you want to delete this Supervisor?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {
          ToastifyService.success(`Supervisor Deleted Successfully`);
          const newData = [...data];
          newData.splice(index, 1);
          setData(newData);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`Something went wrong`);
      });
  };

  console.log(SupervisorList);
  const columns = [
    {
      name: "ID",
      selector: (row) => <div>{row.id}</div>,
      width: "50px",
    },
    {
      name: "Supervisor Name",
      selector: (row) => (
        <div>
          <h6 className="mb-0 fw-semibold">
            <Link>{row.fullName}</Link>
          </h6>
          <span className="fs-xs text-secondary">{row.email}</span>
        </div>
      ),
    },
    {
      name: "UserName",
      selector: (row) => row.userName,
    },
    {
      name: "Modified On",
      selector: (row) =>
        format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            // onClick={() => handleEditDiscount(true, row)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDelete(true)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
      width: "200px",
    },
  ];
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16">Supervisor</h4>
        </div>
        <div>
          {/* <div className="d-flex gap-2">
                    <Button variant="dark">
                        <i className="ri-add-line align-middle"></i> Create New Supervisor
                    </Button>
                </div> */}
        </div>
      </div>
      <Card className="card-one">
        <Card.Body>
          {/* {error && <p className="text-center" style={{ color: "red" }}>{error}</p>} */}
          {SupervisorList?.length > 0 ? (
            <DataTable
              columns={columns}
              data={SupervisorList}
              pagination
              highlightOnHover
            />
          ) : (
            !error && <p className="text-center">No records to display</p>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
