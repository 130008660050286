import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";

export default function MappedUsers() {
     // HANDLE DELETE PROJECT
     const [data, setData] = useState([]);
     const handleDelete = (index) => {
         SweetAlertService.showAlert(
             "Project Name",
             "Are you sure you want to delete this Project Name?",
             "warning"
         )
             .then((result) => {
                 if (result.isConfirmed) {
                     ToastifyService.success(`Project Name Deleted Successfully`);
                     const newData = [...data];
                     newData.splice(index, 1);
                     setData(newData);
                 }
             })
             .catch((error) => {
                 console.error("Error:", error);
                 ToastifyService.error(`Something went wrong`);
             });
     };

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>IVT-CGS</td>
                        <td>
                            <Button variant="outline-danger" onClick={()=> handleDelete(true)} className="btn-icon"><i className="ri-delete-bin-line"></i></Button>
                        </td>
                    </tr>

                    <tr>
                        <td>PRIOR AUTH - CANCELLATIONS</td>
                        <td>
                            <Button variant="outline-danger" onClick={()=> handleDelete(true)} className="btn-icon"><i className="ri-delete-bin-line"></i></Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </React.Fragment>
    )
};